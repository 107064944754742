const constants = {
  WELCOME_NODE: "welcome",
  MESSAGE_NODE: "message",
  PROMPT_NODE: "utterance",
  TRIGGER_NODE: "trigger",
  QUESTION_NODE: "question",
  QUESTION_NODE_VARIANT: {
    TEXT: "text",
    NAME: "name",
    EMAIL: "email",
    PHONE: "phone_number",
    DATE: "date",
    FILE: "file",
    DP_CONTRACT: "digital_platform_contract",
  },
  TEMPLATE_NODE: "template",
  TEMPLATE_NODE_VARIANT: {
    APPOINTMENT: "appointment",
    APPOINTMENT_ONOFFICE: "appointment_onoffice",
    REALESTATE_SEARCH: "realestate_search",
    REALESTATE_LEAD_GENERATION: "realestate_lead_generation",
    CONTACT_PERSON_SEARCH: "contact_person_search",
    REALESTATE_SEARCH_SMPLY_GD: "realestate_search_smply_gd",
    REALESTATE_SEARCH_ONOFFICE: "realestate_search_onoffice",
    VALUATION: "valuation",
    ADVANCED_VALUATION: "advanced_valuation",
    TICKET_VALUEMATION: "ticket_valuemation",
    CUSTOMER_SERVICE_AUTHENTICATION: "cs_authentication",
    CUSTOMER_SERVICE_CHANGE_OR_PROCEED: "cs_change_or_proceed",
  },
  TICKET_NODE: "ticket",
  FAQ_NODE: "faq",
  JUMP_NODE: "jump",
  AUTOMATION_PAUSED_NODE: "automation_paused",
  CONDITION_NODE: "condition",
  SWITCH_NODE: "switch",
  REGEX_PROCESS: "regex_processing",
  TEXT_FORMATTER_NODE: "text_formatter",
  GET_LIST_ITEM_NODE: "get_list_item",
  COMBINE_TEXT_NODE: "combine_text",
  COMBINE_LISTS_NODE: "combine_lists",
  FILE_WRITER_NODE: "file_writer",
  CSV_WRITER_NODE: "csv_writer",
  CSV_TO_XLSX_CONVERTER_NODE: "csv_to_xlsx_converter",
  BRICK_NODE: "brick",
  SLOT_NODE: "slot",
  SET_SLOT_NODE: "setslot",
  ACTION_NODE: "action",
  ASK_AI_NODE: "ask_ai",
  FILL_PDF_NODE: "fill_pdf",
  FILE_READER_NODE: "file_reader",
  WEB_SCRAPER_NODE: "webscraper",
  OUTLOOK_READER_NODE: "outlook_reader",
  VIDEO_TRANSCRIPTION_NODE: "video_transcription",
  CSV_READER_NODE: "csv_reader",
  TEXT_TO_SPEECH_NODE: "text_to_speech",
  DECISION_TREE_NODE: "decision_tree",
  DATE_PICKER_PAYLOAD: "date_picker",
  SAVED_STATUS: {
    UNSAVED: "unsaved",
    SUCCESS: "success",
    IN_PROGRESS: "inProgress",
    ERROR: "error",
  },
};

export const drawerWidth = 240;
export const drawerWidthCollapsed = 40;

export default constants;
