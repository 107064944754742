import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchConversations = ({
  pageOffset = 0,
  startDate = "",
  endDate = "",
  order = "DESC",
  sortType = "timestamp",
  language = "",
  selectedTopic = "",
  tagId = "",
  isFlagged = "",
  userGaveFeedback = "",
  emptyMessageFilter = "",
  channel = "ALL",
  text = "",
  inboxId = "",
  labelId = "",
  caseStatus = "open",
  assignedTo = "",
  hasUsedFreetext = "",
  hasCreatedTicket = "",
  hasDeniedTicket = "",
  hasUsedSuggestion = "",
  hasUsedFallback = "",
  reviewState = "",
  includeArchived = false,
} = {}) => ({
  type: Actions.FETCH_CONVERSATIONS,
  payload: {
    pageOffset,
    startDate,
    endDate,
    order,
    sortType,
    language,
    selectedTopic,
    tagId,
    isFlagged,
    userGaveFeedback,
    emptyMessageFilter,
    channel,
    text,
    inboxId,
    labelId,
    caseStatus,
    assignedTo,
    hasUsedFreetext,
    hasCreatedTicket,
    hasDeniedTicket,
    reviewState,
    hasUsedSuggestion,
    hasUsedFallback,
    includeArchived,
  },
  token: getKeycloakToken(),
});

export const setConversations = (data) => ({
  type: Actions.SET_CONVERSATIONS,
  payload: data,
});

export const setConversationsCount = (data) => ({
  type: Actions.SET_CONVERSATIONS_COUNT,
  payload: data,
});

export const setConversationsCountAssigned = (data) => ({
  type: Actions.SET_CONVERSATIONS_COUNT_ASSIGNED,
  payload: data,
});

export const setConversationsCountUnassigned = (data) => ({
  type: Actions.SET_CONVERSATIONS_COUNT_UNASSIGNED,
  payload: data,
});

export const fetchConversation = (data) => ({
  type: Actions.FETCH_CONVERSATION,
  payload: data,
  token: getKeycloakToken(),
});

export const setConversation = (data) => ({
  type: Actions.SET_CONVERSATION,
  payload: data,
});

export const updateConversation = (conversationId, data) => ({
  type: Actions.UPDATE_CONVERSATION,
  conversationId: conversationId,
  payload: data,
  token: getKeycloakToken(),
});

export const updateConversationFulfilled = () => ({
  type: Actions.UPDATE_CONVERSATION_FULFILLED,
});

export const updateConversationRead = (conversationId) => ({
  type: Actions.UPDATE_CONVERSATION_READ,
  conversationId: conversationId,
  token: getKeycloakToken(),
});

export const updateConversationReadFulfilled = () => ({
  type: Actions.UPDATE_CONVERSATION_READ_FULFILLED,
});

export const fetchConversationTracker = (conversationId) => ({
  type: Actions.FETCH_CONVERSATION_TRACKER,
  conversationId: conversationId,
  token: getKeycloakToken(),
});

export const setConversationTracker = (data) => ({
  type: Actions.SET_CONVERSATION_TRACKER,
  payload: data,
});
export const setAgentAssistResponse = (conversationId, message) => ({
  type: Actions.SET_AGENT_ASSIST_RESPONSE,
  conversationId: conversationId,
  payload: message,
});

export const fetchConversationIdentity = (conversationId) => ({
  type: Actions.FETCH_CONVERSATION_IDENTITY,
  conversationId: conversationId,
  token: getKeycloakToken(),
});

export const fetchConversationIdentityFulfilled = () => ({
  type: Actions.FETCH_CONVERSATION_IDENTITY_FULFILLED,
});

export const setConversationIdentity = (conversationId, data) => ({
  type: Actions.SET_CONVERSATION_IDENTITY,
  conversationId: conversationId,
  payload: data,
});

export const saveTenantIdentity = (conversationId, tenantId) => ({
  type: Actions.SAVE_TENANT_IDENTITY,
  conversationId: conversationId,
  tenantId: tenantId,
  token: getKeycloakToken(),
});

export const setTenantIdentity = (tenantId, data) => ({
  type: Actions.SET_TENANT_IDENTITY,
  tenantId: tenantId,
  payload: data,
});

export const createNote = (conversationId, data) => ({
  type: Actions.CREATE_NOTE,
  conversationId: conversationId,
  payload: data,
  token: getKeycloakToken(),
});

export const createNoteFulfilled = () => ({
  type: Actions.CREATE_NOTE_FULFILLED,
});

export const addEvent = (conversationId, event, data) => ({
  type: Actions.ADD_EVENT,
  conversationId: conversationId,
  event: event,
  payload: data,
  token: getKeycloakToken(),
});

export const addEventFulfilled = () => ({
  type: Actions.ADD_EVENT_FULFILLED,
});

export const updatePost = (conversationId, data, dialogueGraphId = 0) => ({
  type: Actions.UPDATE_POST,
  conversationId: conversationId,
  dialogueGraphId: dialogueGraphId,
  payload: data,
  token: getKeycloakToken(),
});

export const updatePostFulfilled = () => ({
  type: Actions.UPDATE_POST_FULFILLED,
});

export const fetchTranslatedConversation = (conversationId, language) => ({
  type: Actions.FETCH_TRANSLATED_CONVERSATION,
  payload: conversationId,
  language: language,
  token: getKeycloakToken(),
});

export const setTranslatedConversation = (data) => ({
  type: Actions.SET_TRANSLATED_CONVERSATION,
  payload: data,
});

export const fetchConversationAttachments = (conversationId) => ({
  type: Actions.FETCH_CONVERSATION_ATTACHMENTS,
  conversationId: conversationId,
  token: getKeycloakToken(),
});
export const setConversationAttachments = (data) => ({
  type: Actions.SET_CONVERSATION_ATTACHMENTS,
  payload: data,
});

export const fetchAttachment = (conversationId, fileId) => ({
  type: Actions.FETCH_ATTACHMENT,
  conversationId: conversationId,
  fileId: fileId,
  token: getKeycloakToken(),
});

export const setAttachmentFile = (data) => ({
  type: Actions.SET_ATTACHMENT_FILE,
  payload: data,
});

export const fetchTicketAnswer = (conversationId) => ({
  type: Actions.FETCH_TICKET_ANSWER,
  conversationId: conversationId,
  token: getKeycloakToken(),
});

export const setTicketAnswer = (data) => ({
  type: Actions.SET_TICKET_ANSWER,
  payload: data,
});

export const archiveConversation = (data) => ({
  type: Actions.ARCHIVE_CONVERSATION,
  senderId: data.senderId,
  documentId: data.documentId ? data.documentId : null,
  collectionId: data.collectionId ? data.collectionId : null,
  llm_model: data.llm_model ? data.llm_model : null,
  token: getKeycloakToken(),
});
