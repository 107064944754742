import { Provider } from "react-redux";
import { getOrInitializePersistor, useStore } from "../store/store";
import { useRouter } from "next/router";
import KeycloakProvider from "../components/wrapper/KeycloakProvider";
import UnleashProvider from "../components/wrapper/UnleashProvider";
import MUIProvider from "../components/wrapper/MUIProvider";
import BaseLayout from "../components/layouts/BaseLayout";
import CopilotBaseLayout from "../components/layouts/CopilotBaseLayout";
import { NextIntlProvider } from "next-intl";
import createEmotionCache from "../lib/createEmotionCache";
import { CacheProvider } from "@emotion/react";
import { MyAppProps } from "../types";

// global styles that need to be imported for some libraries
import "../features/external.scss";
import "rsuite/DateRangePicker/styles/index.less";
import { PersistGate } from "redux-persist/integration/react";
// import { persistStore } from "redux-persist";
import PageBackdrop from "components/common/PageBackdrop";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const MyApp = (
  { Component, emotionCache = clientSideEmotionCache, pageProps }: MyAppProps,
  cookies: any
) => {
  const store = useStore(pageProps?.initialReduxState);
  const router = useRouter();
  const { tenant } = router.query;
  const AnyComponent = Component as any;

  return (
    <MUIProvider>
      <KeycloakProvider tenant={tenant} cookies={cookies}>
        <UnleashProvider tenant={tenant}>
          <NextIntlProvider
            messages={pageProps.messages}
            onError={(error) => {
              console.warn("intl error", error.originalMessage);
            }}
          >
            <Provider store={store}>
              {/* @ts-ignore */}
              <PersistGate
                loading={<PageBackdrop open={true} />}
                persistor={getOrInitializePersistor(store)}
              >
                <CacheProvider value={emotionCache}>
                  {pageProps.copilot ? (
                    <CopilotBaseLayout>
                      <AnyComponent {...pageProps} />
                    </CopilotBaseLayout>
                  ) : (
                    <BaseLayout>
                      <AnyComponent {...pageProps} />
                    </BaseLayout>
                  )}
                </CacheProvider>
              </PersistGate>
            </Provider>
          </NextIntlProvider>
        </UnleashProvider>
      </KeycloakProvider>
    </MUIProvider>
  );
};

export default MyApp;
